<template>
  <div class="ss-statistics-skill-student">
    <SkillQuery :big-area-enabled="false"
                :cancel-enabled="false"
                :exam-pass-status-enabled="false"
                :show-shop-type="true"
                :show-select-all-org="true"
                :downloading="downloading"
                @reset="onReset"
                @search="onSearch"
                @export="exportExcel"/>

    <a-table :columns="columns"
             :data-source="data"
             :pagination="false"
             :loading="loading"
             bordered
             row-key="rowKey"
             :scroll="{x: '100%'}"
             class="table small-cell-table">

      <template slot="PassRate" slot-scope="t, r">{{ r.PassRate }}</template>

      <template slot="action" slot-scope="text, record">
        <div class="table-cell-wrapper">
          <a-button type="link" size="small" @click="viewPersonDetail(record)">查询个人明细</a-button>
        </div>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"></Pagination>
      </template>
    </a-table>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Common from '@/utils/Common';
import {getSkillStudentData, getSkillStudentDataDownload} from '@/api/statistics/Index';
import {statisticsSkillStudentDetail} from '@/utils/Navs/StatisticsNavs';

import Pagination, {getPagination} from '@components/Pagination';
import SkillQuery from '../../components/SkillQuery';


const columns = [
  {
    width: '',
    align: 'center',
    dataIndex: 'user_id',
    key: 'user_id',
    title: 'ID',
  },
  {
    width: '',
    align: 'center',
    title: '大区',
    dataIndex: 'regionName',
    key: 'regionName',
  },
  {
    width: '',
    align: 'center',
    title: '机构',
    dataIndex: 'orgName',
    key: 'orgName',
  },
  {
    width: '',
    align: 'center',
    title: '姓名',
    dataIndex: 'truename',
    key: 'truename',
  },
  {
    width: '',
    align: 'center',
    title: '电话',
    dataIndex: 'mobile',
    key: 'mobile',
  },
  {
    width: '',
    align: 'center',
    title: '身份证',
    dataIndex: 'idcard',
    key: 'idcard',
  },
  {
    width: '',
    align: 'center',
    title: '岗位',
    dataIndex: 'postName',
    key: 'postName',
  },
  {
    width: '120px',
    align: 'center',
    title: '是否封禁',
    dataIndex: 'is_ban',
    key: 'is_ban',
  },
  {
    width: '',
    align: 'center',
    title: '是否参与',
    dataIndex: 'isJoin',
    key: 'isJoin',
  },
  {
    width: '',
    align: 'center',
    title: '是否通过',
    dataIndex: 'isPass',
    key: 'isPass',
  },
  {
    width: '',
    align: 'center',
    title: '人次通过率',
    dataIndex: 'PassRate',
    key: 'PassRate',
    scopedSlots: {customRender: 'PassRate'},
  },
  {
    width: '',
    align: 'center',
    title: '次均时长(秒)',
    dataIndex: 'avgUseTime',
    key: 'avgUseTime',
  },
  {
    width: '',
    align: 'center',
    title: '参与次数',
    dataIndex: 'JoinNum',
    key: 'JoinNum',
  },
  {
    width: '',
    align: 'center',
    title: '通过次数',
    dataIndex: 'PassNum',
    key: 'PassNum',
  },
  {
    width: '',
    align: 'center',
    title: '未通过次数',
    dataIndex: 'notPassNum',
    key: 'notPassNum',
  },
  {
    width: '',
    align: 'center',
    title: '累计时长(秒)',
    dataIndex: 'totalUseTime',
    key: 'totalUseTime',
  },
  {
    width: '',
    align: 'center',
    title: '最高分',
    dataIndex: 'maxScore',
    key: 'maxScore',
  },
  {
    width: '',
    align: 'center',
    title: '操作',
    key: 'action',
    scopedSlots: {customRender: 'action'},
  },
];


export default {
  name: 'StatisticsSkillStudent',
  components: {Pagination, SkillQuery},
  computed: {
    ...mapState({
      queries: state => state.Statistics.speechSkillQuery,
    }),
  },
  data() {
    return {
      loading: false,
      downloading: false,
      columns,
      data: [],

      pagination: {...getPagination(), showSizeChanger: false},
    };
  },
  created() {
    if (this.queries?.skill && this.queries?.org && this.queries?.job) {
      this.onSearch();
    }
  },
  destroyed() {
  },
  methods: {
    exportExcel() {
      const params = this.getParams(this.pagination.current, this.pagination.pageSize, true);

      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }
      if (!this.queries?.org) {
        return this.$message.info({content: '请选择组织机构'});
      }
      if (!this.queries?.job) {
        return this.$message.info({content: '请选择岗位'});
      }

      this.downloading = true;
      getSkillStudentDataDownload(params).then(() => this.downloading = false).catch(() => this.downloading = false);
    },

    onReset() {
      this.data = [];
      this.pagination = { ...getPagination(), showSizeChanger: false };
    },
    onSearch() {
      this.getData(1, this.pagination.defaultPageSize);
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    async getData(page, pageSize) {
      page = page || 1;
      pageSize = pageSize || this.pagination.defaultPageSize;

      if (!this.queries?.skill) {
        return this.$message.info({content: '请选择话术'});
      }
      if (!this.queries?.org) {
        return this.$message.info({content: '请选择组织机构'});
      }
      if (!this.queries?.job) {
        return this.$message.info({content: '请选择岗位'});
      }

      if (this.loading) {
        return;
      }
      this.loading = true;

      const params = this.getParams(page, pageSize, false);

      const data = await getSkillStudentData(params).finally(() => this.loading = false);
      if (!data || data.error_code) {
        return;
      }
      const result = data?.data || [];
      result.forEach((item, idx) => {
        item.rowKey = idx + 1 + (page - 1) * pageSize;
        item.rowKey = 'data-' + item.rowKey;
      });
      this.data = result;

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },
    getParams(page, pageSize, isDown) {
      const params = {
        page: page,
        per_page: pageSize,
      };

      this.queries?.startDateTime && (params.start_time = this.queries.startDateTime);
      this.queries?.endDateTime && (params.end_time = this.queries.endDateTime);
      this.queries?.skill && (params.skill_ids = this.queries.skill);
      this.queries?.skillType && (params.speech_type = this.queries.skillType);
      this.queries?.job && (params.post_ids = this.queries.job);
      this.queries?.org && (params.org_ids = this.queries.org);
      // this.queries?.passStatus && (params.status = this.queries.passStatus);
      this.queries?.filterTxt && (params.user = this.queries.filterTxt);
      if (this.queries?.shopType) {
        params.store_state = this.queries.shopType.store_state || '';
        params.store_type = this.queries.shopType.store_type || '';
        params.operation_state = this.queries.shopType.operation_state || '';
        params.store_age = this.queries.shopType.store_age || '';
      }
      isDown && (params.down = 1);

      return params;
    },

    // 查询个人明细
    viewPersonDetail(item) {
      this.$store.commit('Statistics/updateStudentInfo', {id: item.user_id, name: item.truename});
      this.$router.replace({
        path: statisticsSkillStudentDetail[0].path,
        query: {
          uid: item.user_id,
          uname: item.truename
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.ss-statistics-skill-student {
  width: 100%;
}
</style>
